'use strict';

var creditCard = require('../braintreeCreditCard');
var creditCardFields = require('./creditcardFields');

function submitCreditCardForm(addCreditCardForm) {
    // var defaultPayment = $('#makeDefaultPayment').val();
    creditCard.startTokenize(function (result) {
        if (result.error) {
            return;
        }
        addCreditCardForm.spinner().start();
        $.ajax({
            url: addCreditCardForm.attr('action'),
            type: 'post',
            dataType: 'json',
            data: addCreditCardForm.serialize(),
            success: function (data) {
                addCreditCardForm.spinner().stop();
                if (!data.success) {
                    document.querySelector('#braintreeCreditCardErrorContainer').textContent = data.error;
                } else {
                    location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                addCreditCardForm.spinner().stop();
            }
        });
    });
    return false;
}
function creditCardForm() {
    $('.js_braintree_addCreditCardForm').submit(function (e) {
        e.preventDefault();
        var $form = $(this);
        $form.spinner().start();
        $('form.js_braintree_addCreditCardForm').trigger('js_braintree_addCreditCardForm:submit', e);
        var $recaptcha = $(this).find('[name="g-recaptcha-response"]');
        if ($recaptcha.length && typeof grecaptcha !== 'undefined') {
            var siteKey = $recaptcha.data('sitekey');
            var action = $form.find('[name="g-recaptcha-action"]').val();
            grecaptcha.execute(siteKey, { action: action }).then(function (token) {
                $recaptcha.val(token);
                submitCreditCardForm($form);
            });
        } else {
            submitCreditCardForm($form);
        }
    });
}

function initAccountAddCreditCard() {
    creditCardFields.initCreditCardFields();
    creditCardForm();
}

module.exports = {
    initAccountAddCreditCard
};
